import '@/public-path'
import '@/fix_for_facebook_webview'

import jQuery from 'jquery'
window.jQuery = window.$ = jQuery

import 'core-js/stable'
import 'regenerator-runtime/runtime'

import 'bootstrap/js/dist/alert'
import 'bootstrap/js/dist/button'
import 'bootstrap/js/dist/collapse'
import 'bootstrap/js/dist/dropdown'
import 'bootstrap/js/dist/modal'
import 'bootstrap/js/dist/popover'
import 'bootstrap/js/dist/tab'
import 'bootstrap/js/dist/tooltip'
import 'bootstrap/js/dist/util'

import 'whatwg-fetch'

import '@/swap-on-load'
import '@/embedly'
import '@/agra.js'
import '@/phoenix-loader.js'
import '@/phoenix/vue-init'
import '@/error-logger-init'
import '@/phoenix/component-loader.js'
import '@/phoenix/session-locale.js'
import '@/local/index.js'
import '@/petition/index.js'
import '@/petition/start/index.js'
import '@/events/form/index.js'
import '@/events/rsvp/index.js'
import '@/events/manage/index.js'
import '@/discussion-forum/index.js'
import '@/share/social-share'
import 'global-i18n'
import '@/cookies_consent/index.js'
import 'eu-switcher'
import '@/events/attendees'
import '@/users/sure-opt-in'
import '@/timeago-loader'
import '@/password-sign-up-field'

import 'vue-multiselect/dist/vue-multiselect.css'
import Rails from '@rails/ujs'
Rails.start()
